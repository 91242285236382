import { TouchEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import confetti from 'canvas-confetti'
import { pause } from './utils/pause'
import { randomInRange } from './utils/randomInRange'
import Avril from './assets/avril.png'
// eslint-disable-next-line
import { motion } from 'framer-motion'

const Button = ({ text, onClick }: { text: string; onClick: (...args: any) => void }) => {
  // if (isMobile) {
  //   return null
  // }

  return (
    <button
      onClick={() => {
        onClick()
      }}
      type="button"
      className="text-pink-500 background-transparent font-bold uppercase px-3 py-1 text-m outline-none focus:outline-none mr-1 mb-1 mt-6 ease-linear transition-all duration-150">
      {text}
    </button>
  )
}

function App() {
  const ref = useRef<any>()

  const fireFinalConfetti = async () => {
    const myConfetti = confetti.create(ref.current, {
      resize: true,
      useWorker: true,
    })

    myConfetti({
      particleCount: 220,
      spread: 210,
      gravity: 0.2,
      // any other options from the global
      // confetti function
      origin: {
        x: 0.5,
        y: 1,
      },
    })

    await pause(500)

    myConfetti({
      particleCount: 220,
      spread: 210,
      gravity: 0.2,
      // any other options from the global
      // confetti function
      origin: {
        x: 0.5,
        y: 1,
      },
    })

    await pause(700)

    myConfetti({
      particleCount: 300,
      spread: 280,
      gravity: 0.1,
      // any other options from the global
      // confetti function
      origin: {
        x: 0.5,
        y: 0.8,
      },
    })
  }

  useEffect(() => {
    const myConfetti = confetti.create(ref.current, {
      resize: true,
      useWorker: true,
    })

    ;(async () => {
      myConfetti({
        particleCount: 170,
        spread: 160,
        // any other options from the global
        // confetti function
      })

      await pause(500)

      confetti({
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      })

      await pause(200)

      confetti({
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      })
      await pause(500)

      myConfetti({
        particleCount: 80,
        spread: 80,
        // any other options from the global
        // confetti function
      })

      await pause(800)

      myConfetti({
        particleCount: 170,
        spread: 190,
        gravity: 0.4,
        // any other options from the global
        // confetti function
        origin: {
          y: 0.6,
        },
      })

      await pause(1200)

      myConfetti({
        particleCount: 220,
        spread: 210,
        gravity: 0.2,
        // any other options from the global
        // confetti function
        origin: {
          x: 0.5,
          y: 1,
        },
      })

      //
    })()
  }, [])

  useEffect(() => {
    const end = Date.now() + 15 * 1000

    // go Buckeyes!
    const colors = ['#bb0000', '#ffffff']

    ;(function frame() {
      // left one
      confetti({
        particleCount: 2,
        angle: 40,
        spread: 55,
        gravity: 0.8,
        origin: { x: 0, y: 1 },
        colors: colors,
      })

      // right one
      confetti({
        particleCount: 2,
        angle: 140,
        spread: 55,
        gravity: 0.8,
        origin: { x: 1, y: 1 },
        colors: colors,
      })

      if (Date.now() < end) {
        requestAnimationFrame(frame)
      }
    })()
  }, [])

  const containerRef = useRef<any>(null)
  const sectionIndexRef = useRef(0)
  const clientY = useRef(0)

  const onTouchStart = useCallback<TouchEventHandler<HTMLDivElement>>(
    ({ changedTouches: touches }) => {
      clientY.current = touches[0].clientY
    },
    []
  )

  const onTouchEnd = useCallback<TouchEventHandler<HTMLDivElement>>(
    ({ changedTouches: touches }) => {
      const currentClientY = touches[0].clientY
      const prevClientY = clientY.current

      const delta = 10

      const goNext = currentClientY < prevClientY - delta
      const goPrev = currentClientY > prevClientY + delta

      if (goNext) {
        scrollToSection()
      }

      if (goPrev) {
        scrollToSection(true)
      }
    },
    []
  )

  const [animationProgress, setAnimationProgress] = useState(0)

  const scrollToSection = useCallback((toPrev = false) => {
    const height = document.body.scrollHeight

    let nextSectionIndex = sectionIndexRef.current + 1

    if (toPrev) {
      nextSectionIndex = sectionIndexRef.current - 1
    }

    if (nextSectionIndex < 0) {
      return null
    }

    if (nextSectionIndex > 2) {
      return null
    }

    if (nextSectionIndex === 2) {
      fireFinalConfetti()
    }

    sectionIndexRef.current = nextSectionIndex

    setTimeout(() => {
      setAnimationProgress(nextSectionIndex)
    }, 200)

    containerRef?.current?.scrollTo({
      top: height * nextSectionIndex,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      ref={containerRef}
      style={{
        height: '100vh',
        overflow: 'hidden',
      }}>
      <div className="flex flex-1 bg-green-100 h-screen flex-col justify-center items-center">
        <motion.div
          className="flex flex-1 bg-green-100 h-screen flex-col justify-center items-center"
          initial={{ scale: 0 }}
          animate={{
            rotate: animationProgress === 0 ? 0 : 360,
            scale: animationProgress === 0 ? 1 : 0,
          }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}>
          <h1 className="text-4xl font-bold text-center">Surprise!</h1>
          <br />
          <h2 className="text-2xl font-bold text-center">Zescrolluj i zobacz!</h2>
          <Button text="Albo kliknij tutaj!" onClick={scrollToSection} />
        </motion.div>
      </div>

      <div className="flex flex-1 bg-green-100 h-screen flex-col justify-center items-center">
        <motion.div
          className="flex flex-1 bg-green-100 h-screen flex-col justify-center items-center"
          initial={{ scale: 0 }}
          animate={{
            rotate: animationProgress === 1 ? 0 : 360,
            scale: animationProgress === 1 ? 1 : 0,
          }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}>
          <h1 className="text-4xl font-bold text-center">Jedziemy na...</h1>
          <br />
          <h2 className="text-2xl font-bold text-center">Keep scrolling 😎</h2>
          <Button text="Albo kliknij tutaj!" onClick={scrollToSection} />
        </motion.div>
      </div>

      <div className="flex flex-1 bg-green-100 h-screen flex-col justify-center items-center">
        <motion.div
          className="flex flex-1 bg-green-100 h-screen flex-col justify-center items-center"
          initial={{ scale: 0 }}
          animate={{
            rotate: animationProgress === 2 ? 0 : 360,
            scale: animationProgress === 2 ? 1 : 0,
          }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}>
          <h1 className="text-4xl text-pink-700 font-bold text-center">Koncert Avril!</h1>
          <br />
          <img style={{ maxHeight: '50%' }} src={Avril} />
          <br />
          <h2 className="text-2xl font-bold text-center">Weeeee!</h2>
          <br />
          <h3 className="text-center font-bold text-sm text-pink-700">Bilety są online, Daniel ma!</h3>
        </motion.div>
      </div>

      <canvas
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundColor: 'transparent',
          width: '100vw',
          height: '100vh',
          pointerEvents: 'none',
        }}
        ref={ref}
      />
    </div>
  )
}

export default App
